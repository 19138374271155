import React from 'react'
import Layout from '../components/layout';
import ResponsiveView from '../responsive/view';
import FloatingCart from '../components/floating-cart';

import SingleProductComponent from '../components/product-component';

const Product = () => {

    return (
        <Layout>
            <ResponsiveView render={isMobile => (
                <FloatingCart isMobile={isMobile} />
            )} />

            <ResponsiveView render={isMobile => (
                <SingleProductComponent isMobile={isMobile} />
            )} />
        </Layout>
    )
}

export default Product
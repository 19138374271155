import React from 'react'
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Grid, Card, Button, IconButton} from '@material-ui/core';
import { Product as ProductModel } from '../data/product-model';
import { headerHeight, mobiHeaderHeight, theme } from '../common/site-variables';
import styled  from '@emotion/styled';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import CartContext from '../data/cart-context';
import { navigate } from 'gatsby';

import {
    breakpoints,
    spacing,
  } from '../utils/styles';
import { getFileURL, getProduct } from '../services/product-service';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import RemoveIcon from '@material-ui/icons/Remove'
import AddIcon from '@material-ui/icons/Add'
import BackIcon from '@material-ui/icons/ArrowBack'
import { useQueryParam, StringParam } from "use-query-params";
import { flexAlignCenter, flexColCenter, flexColCenterAll, flexRow, flexRowCenterAll } from '../common/format-styles';
import { toCurrency } from '../helper/to-currency';
import { createMarkup } from '../utils/createMarkup';
import SEO from './seo';

const responsive = {
    all: {
        // the naming can be any, depends on you.
        breakpoint: { max: 6000, min: 0 },
        items: 1
    }
}


interface PropTypes {
    isMobile: boolean;
}

const ProductListingContainer = styled(`div`)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${spacing.lg}px;

  @media (min-width: ${breakpoints.desktop}px) {
    flex-direction: row;
    flex-wrap: wrap;
    padding: ${spacing['2xl']}px;
  }
`;

const SingleProductComponent = (props: PropTypes) => {
    const { isMobile } = props;
    const [productId, setProductID] = useQueryParam('id', StringParam);
    const [product, setProduct] = React.useState<ProductModel>();
    const [prodQuantity, setProdQuantity] = React.useState(1);
    

    const cartState = React.useContext(CartContext);
    
    React.useEffect(() => {
        productId && getProduct(productId).then(
            prod => {
                setProduct(prod.data as ProductModel);                
            }, errors => {
                console.log(errors);
            }
        )
    }, []);


    

    const Root = styled(`section`)`
        padding: 5%;

        width: 100%;
        height: 100%;
    `;

    const Container = styled(Grid)`
        width: 70%;
    `;

    const Images = styled(Grid)`
        .carousel-container {
            width: 100%;

            .carousel-item {
                width: 30vw;
            }
        }

        .imageCard {
            position: relative;
            box-shadow: 0 1px 10px rgba(0,0,0,0.15);
            height: 0;
            padding-top: 56.25%;
            width: 100%;

            img {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 90%;
                height: 90%;
            }
        }
    `;

    const Details = styled(Grid)`
        padding: 0 3%;

        h1 {
            color: ${theme.cs_blue};
            font-size: 1.8rem;
            font-weight: 500;
        }

        p {
            font-size: 1rem;
            line-height: 1.5;
        }

        .priceDiv {
            font-size: 1.8rem;
            font-weight: 500;
            color: ${theme.cs_light_gray};

            .price {
                color: ${theme.cs_blue};
            }
        }

        .quantity {
            margin-top: 5%;

            .btns {
                margin-left: 5%;
            }

            span {
                color: ${theme.cs_light_gray};

                span {
                    color: ${theme.cs_purple};
                }
            }
        }

        .addBtn {
            margin-top: 5%;
            width: 100%;
            background-color: ${theme.cs_yellow};
            color: ${theme.cs_dark_blue};

            svg {
                margin-left: 5%;
            }
        }

        .backBtn {
            margin-bottom: 5%;
            border: solid thin ${theme.cs_blue};
            color: ${theme.cs_dark_blue};
        }
    `;

    const handleIncreaseQuantity = () => {
        setProdQuantity(prodQuantity + 1);
    }

    const handleDecreaseQuantity = () => {
        setProdQuantity(prodQuantity - 1);
    }

    const handleAddCartClick = (product: ProductModel) => () => {
        cartState.updateCartProducts(product, true, false, prodQuantity)
    }


    return (
        <Root css={flexColCenterAll}>
            {product && 
            <React.Fragment>
                <SEO title={`Product-${product && product.title}`} />
                <Container container>
                    <Images item xs={12} sm={12} md={12} lg={6}>
                        <Carousel
                            swipeable
                            draggable
                            responsive={responsive}
                            ssr={true} 
                            containerClass="carousel-container"
                            removeArrowOnDeviceType={["tablet", "mobile"]}
                            itemClass="carousel-item"
                        >
                            {product?.images?.map(image => (
                                <Card css={flexColCenterAll} className="imageCard" key={image.id}>
                                    <img src={getFileURL(image.directus_files_id)} alt=""/>
                                </Card>
                            ))}
                        </Carousel>
                    </Images>

                    <Details item xs={12} sm={12} md={12} lg={6}>
                        <Button className="backBtn" onClick={() => navigate('/products')}>
                            <BackIcon />
                            Back to Product List
                        </Button>

                            <h1>{product.title}</h1>
                            <p>
                                {product.description ?
                                <span dangerouslySetInnerHTML={product.description && createMarkup(product.description)} /> :
                                product.short_description
                                }
                            </p>
                            <div className="priceDiv">
                                <span className="currency">USD </span>
                                <span className="price">{toCurrency(product.price)}</span>
                            </div>

                            <div css={[flexRow, flexAlignCenter]} className="quantity">
                                <span>Quantity: <span>{prodQuantity}</span> </span>
                                <div css={flexRowCenterAll} className="btns">
                                    <IconButton 
                                        onClick={handleDecreaseQuantity}
                                        disabled={prodQuantity === 1}
                                    >
                                        <RemoveIcon fontSize="small" />
                                    </IconButton>

                                    <IconButton onClick={handleIncreaseQuantity}>
                                        <AddIcon fontSize="small" />
                                    </IconButton>
                                </div>

                            </div>

                            <Button className="addBtn" onClick={handleAddCartClick(product)}>
                                Add to cart
                                <ShoppingCartIcon />
                            </Button>
                    </Details>
                </Container>
            </React.Fragment>
            }
        </Root>
    )
}

export default SingleProductComponent
